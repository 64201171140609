<template>
  <div class="job-personal-info-card" :class="isMyOrders ? 'is-in-myOrders' : ''">
      <div class="personal-info-avatar">
          <img :src="personalInfo.avatarUrl"/>
      </div>
      <div class="personal-info-detail">
          <h5 class="personal-info-realName">
              {{personalInfo.realName}}
          </h5>
          <p class="personal-info-signature">
              {{personalInfo.signature}}
          </p>
          <div class="personal-info-text" v-if="personalInfoShowItems && personalInfoShowItems.length">
              <span class="personal-info-mobile" v-if="personalInfoShowItems.indexOf('mobile') > -1">
                  <font-icon class="personal-info-icon" href="#icon-mobile-full"></font-icon>
                  {{personalInfo.mobile}}
              </span>
              <span class="personal-info-wechat" v-if="personalInfoShowItems.indexOf('wechat') > -1">
                  <font-icon class="personal-info-icon" href="#icon-wechat-full"></font-icon>
                  {{personalInfo.wechat}}
              </span>
              <span class="personal-info-email" v-if="personalInfoShowItems.indexOf('email') > -1">
                  <font-icon class="personal-info-icon" href="#icon-email-full"></font-icon>
                  {{personalInfo.email}}
              </span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        personalInfo: {
            type: Object,
            default: {
                avatarUrl: "",
                realName: "",
                signature: "",
                mobile: "",
                wechat: "",
                email: ""
            }
        },
        personalInfoShowItems: {
            type: Array
        }
    },
    data() {
        return {
            isMyOrders: false
        }
    },
    mounted() {
        if(location.href.indexOf('/myOrders') > -1) {
            this.isMyOrders = true;
        }
    }
}
</script>

<style lang="scss" scope>
    .job-personal-info-card {
        display: flex;
        width: 100%;
        &.is-in-myOrders {
            .personal-info-detail {
                .personal-info-realName, .personal-info-signature, .personal-info-text {
                    color: #666;
                }
            }
        }
        .personal-info-avatar {
            width: 50px;
            height: 50px;
            min-width: 50px;
            min-height: 50px;
            margin-right: 12px;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .personal-info-detail {
            flex-grow: 1;
            .personal-info-realName {
                margin-bottom: 4px;
                font-size: 16px;
                line-height: 22px;
                color: #0D315C;
            }
            .personal-info-signature {
                margin-bottom: 4px;
                font-size: 12px;
                line-height: 18px;
                color: #4E5968;
            }
            .personal-info-text {
                line-height: 20px;
                color: #94A1A5;
                margin-bottom: -4px;
                span {
                    margin-right: 18px;
                    margin-bottom: 4px;
                    display: inline-block;
                    white-space: nowrap;
                    .personal-info-icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
</style>