<template>
    <el-dialog
        width="520px"
        title="我的名片信息"
        custom-class="job-personal-info-dialog"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <span class="first-edit-tip" v-if="isFirstEdit">
            第一次制作职位名片，请先编辑你的名片信息。
        </span>
        <job-personal-info-edit
            ref="jobPersonalInfoEdit"
            :isPersonalInfoIncomplete="isPersonalInfoIncomplete"
            :isSingleShare="false"
            :isUpdateInfo="true"
            :personalInfo="personalInfo"
            :personalEditInfo="personalEditInfo"
            :personalInfoShowItems="personalInfoShowItems"
            @close-dialog="handleCloseDialog"
            @updateUserCardInfoParams="updateUserCardInfoParams"
            @userCardInfoFormValid="(valid) => {userCardInfoFormValid = valid}"
        ></job-personal-info-edit>
        <span v-if="!isPersonalInfoIncomplete" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="personalInfoEditComfirm">保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import JobPersonalInfoEdit from '#/component/common/card/job-personal-info-edit.vue';
import UserService from '#/js/service/userService.js';
import { avatarHost } from '#/component/page/instant-message/utils/avatarHost.js';
export default {
    components: {
        JobPersonalInfoEdit
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            isFirstEdit: false,
            personalInfo: {
                avatarUrl: "",
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "",
                wechatQrcodeUrl: ""
            },
            personalEditInfo: {
                avatarUrl: "",
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "",
                wechatQrcodeUrl: ""
            },
            personalInfoShowItems: [],
            userCardInfoParams: {},
            userCardInfoFormValid: false,
            isPersonalInfoIncomplete: false
        }
    },
    methods: {
        show(isFirstEdit) {
            this.isFirstEdit = isFirstEdit;
            this.dialogVisible = true;
            this.getPersonalInfo();
        },
        getPersonalInfo() {
            this.loading = true;
            UserService.getMyJobShareCardInfo({
                requireBusinessCard: false
            }).then((res) => {
                this.personalInfo = {
                    avatarUrl: `${avatarHost()}/user/${this.$store.state.user.userInfo.unionId}`,
                    realName: res.defaultCard.realName,
                    mobile: res.defaultCard.mobile,
                    wechat: res.defaultCard.wechat,
                    email: res.defaultCard.email,
                    signature: res.signature || "",
                    wechatQrcodeUrl: res.wechatQRCodeData ? `data:${res.wechatQRCodeData.contentType};base64,${res.wechatQRCodeData.avatarURL}` : ""
                };
                this.personalEditInfo = {
                    avatarUrl: `${avatarHost()}/user/${this.$store.state.user.userInfo.unionId}`,
                    realName: res.sharingCard.realName ||res.defaultCard.realName,
                    mobile: res.sharingCard.mobile || res.defaultCard.mobile,
                    wechat: res.sharingCard.wechat || res.defaultCard.wechat,
                    email: res.sharingCard.email || res.defaultCard.email,
                    signature: res.signature || "",
                    wechatQrcodeUrl: res.wechatQRCodeData ? `data:${res.wechatQRCodeData.contentType};base64,${res.wechatQRCodeData.avatarURL}` : ""
                };
                if(res.displayItems) {
                    res.displayItems.forEach((item, index) => {
                        res.displayItems[index] = item.slice(0, 1).toLowerCase() + item.slice(1);
                    })
                }
                this.personalInfoShowItems = res.displayItems || [];
                this.$nextTick(() => {
                    this.$refs.jobPersonalInfoEdit.updateParams();
                })
            }).catch((err) => {
                console.log(err)
                if(err.error && err.error.description) {
                    if(err.error.code === 'NoUserAvatar') {
                        this.isPersonalInfoIncomplete = true;
                    }
                    shortTips(err.error.description)
                } else {
                    shortTips('系统异常，请稍后重试')
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        personalInfoEditComfirm() {
            if(this.userCardInfoFormValid) {
                this.loading = true;
                let params = Object.assign({}, this.userCardInfoParams);
                params.displayItems = [];
                Object.keys(this.userCardInfoParams).forEach(key => {
                    if(key !== 'realName' && key !== 'signature' && params[key]) {
                        params.displayItems.push(key.slice(0, 1).toUpperCase() + key.slice(1));
                    }
                });
                UserService.updateMyJobShareCardInfo(params)
                .then((res) => {
                    console.log(res)
                    shortTips('编辑成功');
                    this.handleCloseDialog();
                    this.$emit('personalInfoEditComfirm', this.userCardInfoParams);
                }).catch((err) => {
                    console.log(err)
                    if(err.error && err.error.description) {
                        shortTips(err.error.description)
                    } else {
                        shortTips('系统异常，请稍后重试')
                    }
                }).finally(() => {
                    this.loading = false;
                })
            } else {
                return;
            }
        },
        updateUserCardInfoParams({userCardInfoParams, personalInfoShowItems}) {
            this.userCardInfoParams = userCardInfoParams;
            this.personalInfoShowItems = personalInfoShowItems;
        },
        handleCloseDialog() {
            this.isPersonalInfoIncomplete = false;
            this.dialogVisible = false;
            this.$refs.jobPersonalInfoEdit.reset();
            this.$refs.jobPersonalInfoEdit.$refs.personalInfoEditForm.resetFields();
        }
    }
}
</script>

<style lang="scss" scope>
    .job-personal-info-dialog.el-dialog {
        .first-edit-tip {
            color: #4A4A4A;
            line-height: 20px;
            margin-bottom: 15px;
            display: inline-block;
        }
    }
</style>
