var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "job-personal-info-card",
      class: _vm.isMyOrders ? "is-in-myOrders" : "",
    },
    [
      _c("div", { staticClass: "personal-info-avatar" }, [
        _c("img", { attrs: { src: _vm.personalInfo.avatarUrl } }),
      ]),
      _c("div", { staticClass: "personal-info-detail" }, [
        _c("h5", { staticClass: "personal-info-realName" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.personalInfo.realName) + "\n        "
          ),
        ]),
        _c("p", { staticClass: "personal-info-signature" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.personalInfo.signature) + "\n        "
          ),
        ]),
        _vm.personalInfoShowItems && _vm.personalInfoShowItems.length
          ? _c("div", { staticClass: "personal-info-text" }, [
              _vm.personalInfoShowItems.indexOf("mobile") > -1
                ? _c(
                    "span",
                    { staticClass: "personal-info-mobile" },
                    [
                      _c("font-icon", {
                        staticClass: "personal-info-icon",
                        attrs: { href: "#icon-mobile-full" },
                      }),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.personalInfo.mobile) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.personalInfoShowItems.indexOf("wechat") > -1
                ? _c(
                    "span",
                    { staticClass: "personal-info-wechat" },
                    [
                      _c("font-icon", {
                        staticClass: "personal-info-icon",
                        attrs: { href: "#icon-wechat-full" },
                      }),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.personalInfo.wechat) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.personalInfoShowItems.indexOf("email") > -1
                ? _c(
                    "span",
                    { staticClass: "personal-info-email" },
                    [
                      _c("font-icon", {
                        staticClass: "personal-info-icon",
                        attrs: { href: "#icon-email-full" },
                      }),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.personalInfo.email) +
                          "\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }