<template>
    <el-dialog
        width="520px"
        custom-class="job-share-edit-dialog"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="job-share-edit-title" slot="title">
            <template v-if="isSingleShare || isFromShareTable">
                编辑职位信息
                <el-tooltip
                    popper-class="single-edit-tip"
                    effect="light"
                    content="灰色文本框为猎必得职位详情对应的内容，白色文本框为分享页面对应的内容，可编辑。"
                    placement="right-start"
                    :visible-arrow="false"
                >
                    <span class="icon-question">
                        <font-icon class="question-hover" href="#icon-talent_ql"></font-icon>
                        <font-icon class="question-no-hover" href="#icon-talent_qd"></font-icon>
                    </span>
                </el-tooltip>
            </template>
            <template v-else-if="jobSharingId">
                编辑对外信息
            </template>
            <template v-else>
                添加到我的名片
            </template>
        </div>
        <div class="job-share-edit-form-container" v-loading="jobInfoLoading">
            <template v-if="isSingleEdit">
                <el-form
                    ref="jobShareEditForm"
                    label-width="0"
                    class="job-share-edit-form"
                    :model="jobShareEditForm"
                    :rules="jobShareEditFormRules"
                >
                    <el-checkbox-group v-model="jobShareItemShowList" @change="handleJobShareItemShow">
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="jobName" disabled>职位名称</el-checkbox>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input :value="jobInfo.jobName" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item prop="jobName">
                                    <el-input v-model="jobShareEditForm.jobName" :maxlength="128"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="customerName">公司名称</el-checkbox>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input :value="jobInfo.customerName" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item prop="customerName">
                                    <el-input v-model="jobShareEditForm.customerName" placeholder="请不要直接写客户名称" :maxlength="64"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="location" disabled>工作地点</el-checkbox>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input :value="jobInfo.location" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <div class="job-info-text">{{jobShareEditForm.location}}</div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="yearOfExperience">工作经验</el-checkbox>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input :value="jobInfo.yearOfExperience" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <div class="job-info-text">{{jobShareEditForm.yearOfExperience}}</div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="degree">学历要求</el-checkbox>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input :value="jobInfo.degree" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <div class="job-info-text">{{jobShareEditForm.degree}}</div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="salary">薪资范围</el-checkbox>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item>
                                    <el-input :value="jobInfo.salary" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item prop="salary">
                                    <el-input class="salary-input" v-model.number="jobShareEditForm.minSalary">
                                        <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                    </el-input>
                                    <el-input class="salary-input" v-model.number="jobShareEditForm.maxSalary">
                                        <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-checkbox label="description" disabled>工作描述</el-checkbox>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item prop="description">
                                    <el-input
                                        :value="jobInfo.description"
                                        :disabled="true"
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"></el-input>
                                    <el-input
                                        v-model="jobShareEditForm.description"
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        :maxlength="8000"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-checkbox-group>
                </el-form>
            </template>
            <template v-else>
                <div class="batch-edit-tip">
                    已勾选{{jobIdList.length}}个职位，<template v-if="sharedCount">其中{{sharedCount}}个职位已在名片中，</template>请选择编辑要分享出去的职位项
                </div>
                <el-checkbox-group class="batch-edit-checkbox" v-model="jobShareItemShowList" @change="handleJobShareItemShow">
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="jobName" disabled>职位名称</el-checkbox>
                        </el-col>
                        <el-col :span="4">
                            <i class="batch-edit-icon el-icon-arrow-down" @click="isBatchEditJobName = false;" v-show="isBatchEditJobName"></i>
                            <i class="batch-edit-icon el-icon-arrow-right" @click="isBatchEditJobName = true;" v-show="!isBatchEditJobName"></i>
                        </el-col>
                    </el-row>
                    <div class="batch-edit-container" id="batch_jobName" v-show="isBatchEditJobName">
                        <template v-for="(job, index) in addShareJobs">
                            <el-row :key="index">
                                <el-col :span="12">
                                    <el-input :value="job.defaultJobInfo.jobName" :disabled="true"></el-input>
                                </el-col>
                                <el-col :span="12">
                                    <el-input class="batch-input-item" v-model="job.sharingJobInfo.jobName" :maxlength="128" @blur="(ev) => { handleBatchEditJobInfo(ev.target, 'jobName', index)}"></el-input>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="customerName">公司名称</el-checkbox>
                        </el-col>
                        <el-col :span="4">
                            <i class="batch-edit-icon el-icon-arrow-down" @click="isBatchEditCustomerName = false;" v-show="isBatchEditCustomerName"></i>
                            <i class="batch-edit-icon el-icon-arrow-right" @click="isBatchEditCustomerName = true;" v-show="!isBatchEditCustomerName"></i>
                        </el-col>
                    </el-row>
                    <div class="batch-edit-container" id="batch_customerName" v-show="isBatchEditCustomerName">
                        <template v-for="(job, index) in addShareJobs">
                            <el-row :key="index">
                                <el-col :span="12">
                                    <el-input :value="job.defaultJobInfo.customerName" :disabled="true"></el-input>
                                </el-col>
                                <el-col :span="12">
                                    <el-input class="batch-input-item" v-model="job.sharingJobInfo.customerName" :maxlength="64" placeholder="请不要直接写客户名称" @blur="(ev) => { handleBatchEditJobInfo(ev.target, 'customerName', index)}"></el-input>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="location" disabled>工作地点</el-checkbox>
                        </el-col>
                    </el-row>
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="yearOfExperience">工作经验</el-checkbox>
                        </el-col>
                    </el-row>
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="degree">学历要求</el-checkbox>
                        </el-col>
                    </el-row>
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="salary">薪资范围</el-checkbox>
                        </el-col>
                        <el-col :span="4">
                            <i class="batch-edit-icon el-icon-arrow-down" @click="isBatchEditSalary = false;" v-show="isBatchEditSalary"></i>
                            <i class="batch-edit-icon el-icon-arrow-right" @click="isBatchEditSalary = true;" v-show="!isBatchEditSalary"></i>
                        </el-col>
                    </el-row>
                    <div class="batch-edit-container" id="batch_salary" v-show="isBatchEditSalary">
                        <template v-for="(job, index) in addShareJobs">
                            <el-row :key="index">
                                <el-col :span="12">
                                    <el-input :value="job.defaultJobInfo.salary" :disabled="true"></el-input>
                                </el-col>
                                <el-col :span="12">
                                    <el-input class="salary-input batch-input-item" v-model.number="job.sharingJobInfo.minSalary" @blur="(ev) => { handleBatchEditJobInfo(ev.target, 'salary', index)}">
                                        <template slot="suffix">K(千)&nbsp;&nbsp;</template>
                                    </el-input>
                                    <el-input class="salary-input" v-model.number="job.sharingJobInfo.maxSalary" @blur="(ev) => { handleBatchEditJobInfo(ev.target, 'salary', index)}">
                                        <template slot="suffix">K(千)</template>
                                    </el-input>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                    <el-row class="batch-edit-row">
                        <el-col :span="4">
                            <el-checkbox label="description" disabled>工作描述</el-checkbox>
                        </el-col>
                        <el-col :span="4">
                            <i class="batch-edit-icon el-icon-arrow-down" @click="isBatchEditDescription = false;" v-show="isBatchEditDescription"></i>
                            <i class="batch-edit-icon el-icon-arrow-right" @click="isBatchEditDescription = true;" v-show="!isBatchEditDescription"></i>
                        </el-col>
                    </el-row>
                    <div class="batch-edit-container" id="batch_description" v-show="isBatchEditDescription">
                        <template v-for="(job, index) in addShareJobs">
                            <el-row :key="index">
                                <el-col :span="24">
                                    <el-input
                                        :value="job.defaultJobInfo.description"
                                        :disabled="true"
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"></el-input>
                                    <el-input
                                        class="batch-input-item"
                                        v-model="job.sharingJobInfo.description"
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        :maxlength="8000"
                                        @blur="(ev) => { handleBatchEditJobInfo(ev.target, 'description', index)}"
                                    ></el-input>
                                </el-col>
                            </el-row>
                        </template>
                    </div>
                </el-checkbox-group>
            </template>
        </div>
        <div class="personal-info-edit">
            <div class="personal-info-edit-title">
                <template v-if="isSingleShare">
                    编辑我的信息（可不选）
                </template>
                <template v-if="!isSingleShare && !isEditPersonalInfo">
                    我的名片信息
                    <span v-if="!isPersonalInfoIncomplete" class="text-operate-btn" @click="isEditPersonalInfo = true">编辑</span>
                </template>
                <template v-if="!isSingleShare && isEditPersonalInfo">
                    编辑我的信息
                </template>
            </div>
            <div v-show="isPersonalInfoIncomplete" class="personal-info-edit-body-incomplete">
                个人资料不完善
                <el-button type="text" @click="jumpPersonalCenter">去补充</el-button>
            </div>
            <div v-show="!isPersonalInfoIncomplete" class="personal-info-edit-body" v-loading="personalInfoLoading">
                <job-personal-info-edit
                    ref="jobPersonalInfoEdit"
                    :isSingleShare="isSingleShare"
                    :isUpdateInfo="!isSingleShare && isEditPersonalInfo"
                    :personalInfo="personalInfo"
                    :personalEditInfo="personalEditInfo"
                    :personalInfoShowItems="isSingleShare ? [] : personalInfoShowItems"
                    @updateUserCardInfoParams="updateUserCardInfoParams"
                    @userCardInfoFormValid="(valid) => {userCardInfoFormValid = valid}"
                    v-show="isSingleShare || (!isSingleShare && isEditPersonalInfo)"
                ></job-personal-info-edit>
                <job-personal-info-card
                    ref="jobPersonalInfoCard"
                    :personalInfo="personalEditInfo"
                    :personalInfoShowItems="personalInfoShowItems"
                    v-if="!isSingleShare"
                    v-show="!isEditPersonalInfo"
                ></job-personal-info-card>
            </div>
        </div>
        <span v-if="!isPersonalInfoIncomplete" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirmAndShare">{{isFromShareTable ? '保存' : '保存并分享'}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { job as jobUrl } from '#/js/config/api.json';
import JobService from '#/js/service/jobService.js';
import UserService from '#/js/service/userService.js';
import JobPersonalInfoEdit from '#/component/common/card/job-personal-info-edit.vue';
import JobPersonalInfoCard from '#/component/common/card/job-personal-info-card.vue';
import cityMap from '#/js/config/provinceCity.json'
import { avatarHost } from '#/component/page/instant-message/utils/avatarHost.js';

const YearOfExperienceMap = {
    0: "经验不限",
    1: "1-3年",
    3: "3-5年",
    5: "5-10年",
    10: "10年以上"
};

const DegreeMap = {
    "0": "不要求",
    "1": "高中及以下",
    "2": "大专",
    "3": "本科",
    "4": "硕士",
    "5": "MBA",
    "6": "博士"
}
export default {
    props: {
        // 是否是编辑单个职位
        isSingleEdit: {
            type: Boolean,
            default: true
        },
        isFromShareTable: {
            type: Boolean,
            default: false
        }
    },
    components: {
        JobPersonalInfoEdit,
        JobPersonalInfoCard
    },
    data() {
        return {
            isSingleShare: true,    //是否是单个职位分享
            loading: false,
            jobInfoLoading: false,
            personalInfoLoading: false,
            dialogVisible: false,
            jobInfo: {
                jobName: "",
                customerName: "",
                location: "",
                yearOfExperience: "",
                degree: "",
                salary: "",
                description:  ""
            },
            jobShareEditForm: {
                jobName: "",
                customerName: "",
                location: "",
                yearOfExperience: "",
                degree: "",
                salary: "",
                minSalary: 0,
                maxSalary: 0,
                description:  ""
            },
            addShareJobs: [],
            isBatchEditJobName: false,
            isBatchEditCustomerName: false,
            isBatchEditSalary: false,
            isBatchEditDescription: false,
            jobShareEditFormRules: {
                jobName: [{
                    required: true, message: '请填写职位名称', trigger: 'blur'
                }],
                customerName: [{
                    validator: (rules, value, callback) => {
                        this.validateJobShareItem('customerName', value, callback)
                    }, trigger: 'blur'
                }],
                salary: [{
                    validator: (rules, value, callback) => {
                        this.validateJobShareItem('salary', value, callback)
                    }, trigger: 'blur'
                }],
                description: [{
                    required: true, message: '请填写工作描述', trigger: 'blur'
                }]
            },
            jobShareItemShowList: ['jobName', 'location', 'yearOfExperience', 'degree', 'description'],
            isEditPersonalInfo: false,
            personalInfo: {
                avatarUrl: "",
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "",
                wechatQrcodeUrl: ""
            },
            personalEditInfo: {
                avatarUrl: "",
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "",
                wechatQrcodeUrl: ""
            },
            personalInfoShowItems: [],
            userCardInfoParams: {},
            userCardInfoFormValid: false,
            jobSharingId: "",
            sharedCount: 0,
            canNotShareCount: 0,
            jobIdList: [],
            isPersonalInfoIncomplete: false
        }
    },
    computed: {
        unionId() {
            return this.$store.state.user.userInfo.unionId;
        }
    },
    methods: {
        // 单个职位分享，isSingleShare = true
        // 单个职位分享/添加到职位名片/编辑对外信息，需要传jobId
        // 编辑对外信息，需要传jobSharingId
        // 批量添加到职位名片，需要传jobIdList
        show(isSingleShare, params = {jobId = "", jobSharingId = "", jobIdList} = {}) {
            this.isSingleShare = isSingleShare;
            this.jobId = params.jobId || "";
            this.jobSharingId = params.jobSharingId || "";
            this.jobIdList = params.jobIdList || [];
            this.dialogVisible = true;
            this.getJobShareInfo();
            this.getPersonalInfo();
        },
        getJobShareInfo() {
            this.jobInfoLoading = true;
            if(this.isSingleEdit) {
                let params = {};
                params.jobSharingCategory = this.isSingleShare ? 1 : 2;
                if(this.jobSharingId) {
                    params.jobSharingId = this.jobSharingId;
                    params.isUpdated = true;
                } else {
                    params.jobId = this.jobId;
                    params.isUpdated = false;
                }
                JobService.getSingleJobShareInfo(params)
                .then((res) => {
                    this.jobInfo = this.convertsJobInfoItem(res.defaultJobInfo);
                    if(this.isFromShareTable) {
                        let jobShareItemShowList = [];
                        Object.keys(res.sharingJobInfo).forEach(key => {
                            if(res.sharingJobInfo[key] !== null) {
                                if(['locationIds', 'maxSalary', 'minDegree', 'minSalary', 'minYearOfExperience'].indexOf(key) > -1) {
                                    if((key === 'maxSalary' || key === 'minSalary') && jobShareItemShowList.indexOf('salary') === -1) {
                                        jobShareItemShowList.push('salary')
                                    }
                                    if(key === 'locationIds') {
                                        jobShareItemShowList.push('location')
                                    }
                                    if(key === 'minDegree') {
                                        jobShareItemShowList.push('degree')
                                    }
                                    if(key === 'minYearOfExperience') {
                                        jobShareItemShowList.push('yearOfExperience')
                                    }
                                } else {
                                    jobShareItemShowList.push(key)
                                }
                            } else {
                                if(key !== 'customerName') {
                                    res.sharingJobInfo[key] = res.defaultJobInfo[key]
                                }
                            }
                        });
                        this.jobShareItemShowList = jobShareItemShowList;
                    }
                    this.jobShareEditForm = this.convertsJobInfoItem(res.sharingJobInfo);
                }).catch((err) => {
                    console.log(err)
                    if(err.error && err.error.description) {
                        if(!this.isSingleShare && err.error.code === 'JobSharingInfoExist') {
                            this.$emit('shareJobCard')
                            this.handleCloseDialog();
                        }
                        shortTips(err.error.description)
                    } else {
                        shortTips('系统异常，请稍后重试')
                    }
                }).finally(() => {
                    this.jobInfoLoading = false;
                })
            } else {
                JobService.getBatchJobShareInfo(this.jobIdList)
                .then((res) => {
                    this.sharedCount = res.sharedCount;
                    this.canNotShareCount = res.canNotShareCount;
                    if(res.canNotShareCount || res.sharedCount === this.jobIdList.length) {
                        this.handleCloseDialog();
                        let message = "";
                        if(res.canNotShareCount) {
                            message = '请重新选择，以下职位不能添加到职位名片<br/>1.职位为已暂停/已完成<br/>2.职位非本公司成员职位创建';
                        } else {
                            message = '你选择的职位已经在职位名片中，请到 我分享的职位 查看。';
                        }
                        this.$alert(message, '提示', {
                            dangerouslyUseHTMLString: true,
                            customClass: 'batch-job-share-alert-dialog',
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            confirmButtonText: res.canNotShareCount ? '确认' : '去查看',
                            callback: (action) => {
                                if(res.canNotShareCount === 0 && action === 'confirm') {
                                    location.href = "/Headhunting/#/myOrders/sharedJob";
                                }
                            }
                        })
                    } else {
                        res.jobInfoes.forEach(job => {
                            job.defaultJobInfo = this.convertsJobInfoItem(job.defaultJobInfo);
                            job.sharingJobInfo = this.convertsJobInfoItem(job.sharingJobInfo);
                        })
                        this.addShareJobs = [].concat(res.jobInfoes);
                    }
                }).catch((err) => {
                    console.log(err)
                    if(err.error && err.error.description) {
                        if(err.error.code === 'JobSharingInfoExist') {
                            this.$emit('shareJobCard')
                            this.handleCloseDialog();
                        }
                        if(err.error.code !== 'NoBusinessCard') {
                            shortTips(err.error.description)
                        }
                    } else {
                        shortTips('系统异常，请稍后重试')
                    }
                }).finally(() => {
                    this.jobInfoLoading = false;
                })
            }
        },
        getPersonalInfo() {
            this.personalInfoLoading = true;
            UserService.getMyJobShareCardInfo({
                requireBusinessCard: !this.isSingleShare
            }).then((res) => {
                this.personalInfo = {
                    avatarUrl: `${avatarHost()}/user/${this.$store.state.user.userInfo.unionId}`,
                    realName: res.defaultCard.realName,
                    mobile: res.defaultCard.mobile,
                    wechat: res.defaultCard.wechat,
                    email: res.defaultCard.email,
                    signature: res.signature,
                    wechatQrcodeUrl: res.wechatQRCodeData ? `data:${res.wechatQRCodeData.contentType};base64,${res.wechatQRCodeData.avatarURL}` : ""
                };
                // if(this.isSingleShare) {
                //     this.personalEditInfo = Object.assign({}, this.personalInfo);
                // } else {
                this.personalEditInfo = {
                    avatarUrl: `${avatarHost()}/user/${this.$store.state.user.userInfo.unionId}`,
                    realName: res.sharingCard.realName || res.defaultCard.realName,
                    mobile: res.sharingCard.mobile || res.defaultCard.mobile,
                    wechat: res.sharingCard.wechat || res.defaultCard.wechat,
                    email: res.sharingCard.email || res.defaultCard.email,
                    signature: res.signature,
                    wechatQrcodeUrl: res.wechatQRCodeData ? `data:${res.wechatQRCodeData.contentType};base64,${res.wechatQRCodeData.avatarURL}` : ""
                };
                if(!this.isSingleShare && res.displayItems) {
                    res.displayItems.forEach((item, index) => {
                        res.displayItems[index] = item.slice(0, 1).toLowerCase() + item.slice(1)
                    })
                    this.personalInfoShowItems = res.displayItems || [];
                }
                // }
                this.$nextTick(() => {
                    this.$refs.jobPersonalInfoEdit.updateParams();
                })
            }).catch((err) => {
                console.log(err)
                if(err.error && err.error.description) {
                    if(err.error.code === 'NoUserAvatar') {
                        this.isPersonalInfoIncomplete = true;
                    }
                    if(err.error.code === 'NoBusinessCard') {
                        this.handleCloseDialog();
                        this.$emit('firstEditPersonalInfo');
                    } else {
                        shortTips(err.error.description)
                    }
                } else {
                    shortTips('系统异常，请稍后重试')
                }
            }).finally(() => {
                this.personalInfoLoading = false;
            })
        },
        convertsJobInfoItem(json) {
            let locationTextList = [],
                location = "",
                yearOfExperience = "",
                degree = "",
                salary = "";
            json.locationIds.forEach(id => {
                locationTextList.push(cityMap[id]);
            })
            location = locationTextList.join('、');
            yearOfExperience = YearOfExperienceMap[json.minYearOfExperience];
            degree = DegreeMap[json.minDegree];
            salary = `${json.minSalary}K-${json.maxSalary}K`;
            return {
                jobName: json.jobName,
                customerName: json.customerName,
                locationIds: json.locationIds,
                location: location,
                minYearOfExperience: json.minYearOfExperience,
                yearOfExperience: yearOfExperience,
                minDegree: json.minDegree,
                degree: degree,
                salary: salary,
                minSalary: json.minSalary,
                maxSalary: json.maxSalary,
                description:  json.description
            }
        },
        handleJobShareItemShow() {
            if(this.isSingleEdit) {
                this.$refs.jobShareEditForm.validate();
            } else {
                let itemNameList = ['jobName', 'customerName', 'salary', 'description'];
                itemNameList.forEach(item => {
                    let batchEditContainerElm = document.querySelector(`#batch_${item}`),
                        inputTarget = "";
                    if(batchEditContainerElm) {
                        for(let i = 0; i < this.addShareJobs.length; i++) {
                            if(item === 'description') {
                                inputTarget = batchEditContainerElm.querySelectorAll('.batch-input-item')[i].querySelector('.el-textarea__inner');
                            } else {
                                inputTarget = batchEditContainerElm.querySelectorAll('.batch-input-item')[i].querySelector('.el-input__inner');
                            }
                            this.handleBatchEditJobInfo(inputTarget, item, i);
                        }
                    }
                })
            }
        },
        validateJobShareItem(itemName, value, callback) {
            if(this.jobShareItemShowList.indexOf(itemName) > -1) {
                if(itemName === 'salary') {
                    let minSalary = Number(this.jobShareEditForm.minSalary),
                        maxSalary = Number(this.jobShareEditForm.maxSalary);
                    if(isNaN(minSalary) || isNaN(maxSalary)) {
                        callback('薪资要求必须为数字格式')
                    } else if(!(minSalary >= 0 && minSalary < 1000)) {
                        callback('最低月薪的取值范围是0-999')
                    } else if(!(maxSalary >= 0 && maxSalary < 1000)) {
                        callback('最高月薪的取值范围是0-999')
                    } else if(minSalary > maxSalary) {
                        callback('最低月薪不能大于最高月薪')
                    } else {
                        callback()
                    }
                } else if(!value) {
                    callback('请填写客户名称')
                } else {
                    callback()
                }
            } else {
                callback()
            }
        },
        updateUserCardInfoParams({userCardInfoParams, personalInfoShowItems}) {
            this.userCardInfoParams = userCardInfoParams;
            this.personalInfoShowItems = personalInfoShowItems;
        },
        confirmAndShare() {
            if(this.isSingleEdit) {
                this.$refs.jobShareEditForm.validate((valid) => {
                    if(valid && this.userCardInfoFormValid) {
                        this.loading = true;
                        let jobParams = {};
                        this.jobShareItemShowList.forEach(item => {
                            switch(item) {
                                case 'salary': 
                                    jobParams.minSalary = this.jobShareEditForm.minSalary;
                                    jobParams.maxSalary = this.jobShareEditForm.maxSalary;
                                    break;
                                case 'yearOfExperience':
                                    jobParams.minYearOfExperience = this.jobShareEditForm.minYearOfExperience;
                                    break;
                                case 'degree':
                                    jobParams.minDegree = this.jobShareEditForm.minDegree;
                                    break;
                                case 'location':
                                    jobParams.locationIds = this.jobShareEditForm.locationIds;
                                    break;
                                default:
                                    jobParams[item] = this.jobShareEditForm[item];
                            }
                        })
                        let params = Object.assign({}, jobParams, this.userCardInfoParams);
                        params.cardSharingItems = [];
                        Object.keys(this.userCardInfoParams).forEach(key => {
                            if(this.isSingleShare || (!this.isSingleShare && key !== 'realName' && key !== 'signature' && params[key])) {
                                params.cardSharingItems.push(key.slice(0, 1).toUpperCase() + key.slice(1));
                            }
                        });
                        if(!this.isSingleShare) {
                            params.isUpdateCard = this.isEditPersonalInfo;
                        }
                        if(this.jobSharingId) {
                            params.jobSharingId = this.jobSharingId;
                            JobService.singleJobShareInfoUpdate(params).then(res => {
                                this.handleCloseDialog();
                                this.$emit('shareJobCard');
                                if(this.isFromShareTable) {
                                    shortTips('保存成功！')
                                }
                            }).catch(err => {
                                console.log(err)
                                if(err.error && err.error.description) {
                                    shortTips(err.error.description);
                                } else {
                                    shortTips('系统异常，请稍后重试')
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        } else {
                            params.jobId = this.jobId;
                            params.jobSharingCategory = this.isSingleShare ? 1 : 2;
                            JobService.singleJobShareInfoAdd(params).then(res => {
                                this.handleCloseDialog();
                                if(this.isSingleShare) {
                                    let jobShareUrl = `${_host.h5_page}/jobShareDetail.html?shareId=${res}&unionId=${this.unionId}`
                                    let VNode = `
                                        <img src='${_host.portal}/Sharing/QRCode?url=${encodeURIComponent(jobShareUrl)}'/>
                                        <span>打开微信，扫一扫~<br/>快速分享职位</span>
                                    `;
                                    this.$alert(VNode, '分享职位', {
                                        dangerouslyUseHTMLString: true,
                                        customClass: 'job-share-qcode-dialog',
                                        showConfirmButton: false,
                                        closeOnClickModal: false,
                                        closeOnPressEscape: false
                                    })
                                } else {
                                    this.$emit('shareJobCard')
                                }
                            }).catch(err => {
                                console.log(err)
                                if(err.error && err.error.description) {
                                    if(!this.isSingleShare && err.error.code === 'JobSharingInfoExist') {
                                        this.handleCloseDialog();
                                    }
                                    shortTips(err.error.description);
                                } else {
                                    shortTips('系统异常，请稍后重试')
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        }
                    } else {
                        console.log("error submit");
                    }
                });
            } else {
                this.handleJobShareItemShow();
                if(!document.querySelector('.batch-edit-checkbox').querySelector('.el-form-item__error') && this.userCardInfoFormValid) {
                    this.loading = true;
                    let params = Object.assign({}, this.userCardInfoParams);
                    params.cardSharingItems = [];
                    params.isUpdateCard = this.isEditPersonalInfo;
                    Object.keys(this.userCardInfoParams).forEach(key => {
                        if(key !== 'realName' && key !== 'signature' && params[key]) {
                            params.cardSharingItems.push(key.slice(0, 1).toUpperCase() + key.slice(1));
                        }
                    });
                    let jobSharingItems = [],
                        jobSharingItem = {},
                        jobShareItemShowList = this.jobShareItemShowList;
                    this.addShareJobs.forEach(item => {
                        for(let i = 0; i < jobShareItemShowList.length; i++) {
                            switch(jobShareItemShowList[i]) {
                                case 'salary':
                                    jobSharingItem.minSalary = item.sharingJobInfo.minSalary;
                                    jobSharingItem.maxSalary = item.sharingJobInfo.maxSalary;
                                    break;
                                case 'yearOfExperience':
                                    jobSharingItem.minYearOfExperience = item.sharingJobInfo.minYearOfExperience;
                                    break;
                                case 'degree':
                                    jobSharingItem.minDegree = item.sharingJobInfo.minDegree;
                                    break;
                                case 'location':
                                    jobSharingItem.locationIds = item.sharingJobInfo.locationIds;
                                    break;
                                default:
                                    jobSharingItem[jobShareItemShowList[i]] = item.sharingJobInfo[jobShareItemShowList[i]];
                            }
                        }
                        jobSharingItems.push(Object.assign({jobId: item.jobId}, jobSharingItem));
                    })
                    params.jobSharingItems = jobSharingItems;
                    JobService.batchAddJobShareInfo(params)
                    .then(res => {
                        this.handleCloseDialog();
                        this.$emit('shareJobCard')
                    }).catch(err => {
                        console.log(err)
                        if(err.error && err.error.description) {
                            shortTips(err.error.description)
                        } else {
                            shortTips('系统异常，请稍后重试')
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                } else {
                    shortTips('存在错误填写项，请检查后再提交')
                }
            }
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.isEditPersonalInfo = false;
            this.isPersonalInfoIncomplete = false;
            this.jobShareItemShowList = ['jobName', 'location', 'yearOfExperience', 'degree', 'description'];
            this.$refs.jobPersonalInfoEdit.reset();
            if(this.isSingleEdit) {
                this.$refs.jobShareEditForm.resetFields();
            } else {
                this.addShareJobs = [];
                this.isBatchEditJobName = false;
                this.isBatchEditCustomerName = false;
                this.isBatchEditSalary = false;
                this.isBatchEditDescription = false;
            }
        },
        handleBatchEditJobInfo(target, itemName, index) {
            let parenElm = target.parentElement;
            if(this.jobShareItemShowList.indexOf(itemName) > -1) {
                let errorMsg = "",
                    idName = "";
                if(itemName === 'salary') {
                    let minSalary = Number(this.addShareJobs[index].sharingJobInfo.minSalary),
                        maxSalary = Number(this.addShareJobs[index].sharingJobInfo.maxSalary);
                    if(isNaN(minSalary) || isNaN(maxSalary)) {
                        errorMsg = '薪资要求必须为数字格式';
                    } else if(!(minSalary >= 0 && minSalary < 1000)) {
                        errorMsg = '最低月薪的取值范围是0-999';
                    } else if(!(maxSalary >= 0 && maxSalary < 1000)) {
                        errorMsg = '最高月薪的取值范围是0-999';
                    } else if(minSalary > maxSalary) {
                        errorMsg = '最低月薪不能大于最高月薪';
                    } else {
                        errorMsg = ""
                    }
                } else if(!this.addShareJobs[index].sharingJobInfo[itemName]) {
                    switch(itemName) {
                        case 'jobName': 
                            errorMsg = '请填写职位名称';
                            idName = "batchJobName";
                            break;
                        case 'customerName': 
                            errorMsg = '请填写客户名称';
                            idName = "batchCustomerName";
                            break;
                        case 'description': 
                            errorMsg = '请填写工作描述';
                            idName = "batchDescription";
                            break;
                    }
                } else {
                    errorMsg = '';
                }
                if(errorMsg) {
                    let errorDiv = document.createElement('div');
                    errorDiv.classList.add('el-form-item__error');
                    errorDiv.innerHTML = errorMsg;
                    if(itemName === 'salary' && !parenElm.parentElement.querySelector('.el-form-item__error')) {
                        parenElm.parentElement.querySelector('.batch-input-item').appendChild(errorDiv);
                    } else if(itemName !== 'salary' && !parenElm.querySelector('.el-form-item__error')){
                        parenElm.appendChild(errorDiv);
                    }
                } else if(itemName === 'salary' && parenElm.parentElement.querySelector('.el-form-item__error')) {
                    parenElm.parentElement.querySelector('.batch-input-item').removeChild(parenElm.parentElement.querySelector('.el-form-item__error'));
                } else if(itemName !== 'salary' && parenElm.querySelector('.el-form-item__error')){
                    parenElm.removeChild(parenElm.querySelector('.el-form-item__error'));
                }
            } else {
                if(itemName === 'salary' && parenElm.parentElement.querySelector('.el-form-item__error')) {
                    parenElm.parentElement.querySelector('.batch-input-item').removeChild(parenElm.parentElement.querySelector('.el-form-item__error'));
                } else if(itemName !== 'salary' && parenElm.querySelector('.el-form-item__error')){
                    parenElm.removeChild(parenElm.querySelector('.el-form-item__error'));
                }
            }
        },
        jumpPersonalCenter() {
            this.handleCloseDialog();
            // window.open('/Headhunting/MyCompany.html#/personalCenter?Tab=BasicInfo');
            window.open('/#/personalCenter?Tab=BasicInfo');
        }
    }
}
</script>

<style lang="scss" scope>
    .job-share-edit-dialog.el-dialog {
        .job-share-edit-title {
            font-size: 16px;
            color: #333333;
            .icon-question {
                width: 18px;
                height: 18px;
                display: inline-block;
                .question-hover, .question-no-hover {
                    width: 100%;
                    height: 100%;
                    min-width: unset;
                    min-height: unset;
                }
                .question-hover {
                    display: none;
                }
                &:hover{
                    .question-hover{
                        display: inline-block;
                    }
                    .question-no-hover{
                        display: none;
                    }
                }
            }
        }
        .el-dialog__body {
            padding: 15px 0 20px;
            max-height: 608px;
            overflow: hidden;
            overflow-y: auto;
            .el-col-4 {
                padding-right: 3px;
            }
            .el-col-20, .el-col-10, .el-col-12 {
                padding-left: 15px;
            }
            .el-checkbox {
                .el-checkbox__label {
                    color: #333;
                }
                &.is-disabled.is-checked {
                    .el-checkbox__input .el-checkbox__inner {
                        border-color: #999;
                        background-color: #fff;
                        &::after {
                            border-color: #999;
                        }
                    }
                }
            }
            .el-form-item__error {
                padding-top: 2px;
            }
            .el-form.job-share-edit-form {
                margin-right: 0;
                padding: 0 20px;
                .el-form-item {
                    margin-bottom: 16px;
                }
                .el-form-item__content {
                    line-height: 28px;
                }
            }
            .job-info-text {
                margin-bottom: 16px;
                line-height: 28px;
                font-size: 14px;
                padding: 0 10px;
            }
            .el-input, .el-textarea {
                .el-input__inner {
                    height: 28px;
                    line-height: 28px;
                }
                .el-input__inner, .el-textarea__inner {
                    padding: 0 10px;
                    border-color: #ddd;
                    color: #666;
                }
                &.is-disabled {
                    .el-input__inner, .el-textarea__inner {
                        color: #999;
                        background-color: #f8f8f8;
                    }
                }
                &.salary-input {
                    width: 50%;
                    &:nth-of-type(1) {
                        padding-right: 5px;
                    }
                    &:nth-of-type(2) {
                        padding-left: 5px;
                    }
                }
            }
            .el-textarea .el-textarea__inner {
                padding: 5px 10px;
            }
            .el-textarea + .el-textarea {
                margin-top: 10px;
            }
            .el-textarea.is-disabled .el-textarea__inner {
                resize: none;
            }
            .batch-edit-tip {
                padding: 0 20px;
                line-height: 20px;
                margin-bottom: 15px;
                color: #9B9B9B;
            }
            .batch-edit-checkbox {
                padding: 0 20px;
                .el-input, .el-textarea:nth-child(2) {
                    margin-bottom: 16px;
                }
                .el-input__suffix {
                    line-height: 28px;
                }
            }
            .batch-edit-row {
                margin-bottom: 12px;
                .el-col-24 {
                    padding-left: 15px;
                }
            }
            .batch-edit-container {
                width: 355px;
                margin-left: 9px;
                .salary-input.batch-input-item {
                    .el-form-item__error {
                        white-space: nowrap;
                    }
                }
            }
            .batch-edit-icon {
                margin-left: 20px;
                font-size: 14px;
                line-height: 20px;
                cursor: pointer;
                color: #CACACE;
            }
            .personal-info-edit {
                .personal-info-edit-title {
                    padding: 0 20px;
                    margin-bottom: 15px;
                    height: 34px;
                    line-height: 34px;
                    background-color: #f8f8f8;
                    font-size: 16px;
                    color: #333;
                    .text-operate-btn {
                        margin-left: 8px;
                    }
                }
                .personal-info-edit-body {
                    padding: 0 20px;
                }
                .personal-info-edit-body-incomplete {
                    text-align: center;
                }
            }
        }
    }
</style>
<style lang="scss">
    .single-edit-tip.el-tooltip__popper.is-light {
        margin-left: 0 !important;
        margin-top: 6px;
    }
    .job-share-qcode-dialog.el-message-box {
        width: 386px;
        height: 262px;
        .el-message-box__message > p {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 118px;
                height: 118px;
                margin-bottom: 12px;
            }
            span {
                display: inline-block;
                text-align: center;
                color: #9B9B9B;
                line-height: 20px;
            }
        }
    }
</style>