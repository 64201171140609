<template>
    <div>
        <el-form
            v-if="!isPersonalInfoIncomplete"
            class="personal-info-edit-from"
            ref="personalInfoEditForm"
            label-width="0"
            :model="personalInfoEditForm"
            :rules="personalInfoEditFormRules"
        >
            <el-checkbox-group v-model="personalInfoItemShowList" @change="handlePersonalInfoItemShow">
                <el-row>
                    <el-col :span="4">
                        <el-checkbox label="avatarUrl" :disabled="checkboxDisabled">头像</el-checkbox>
                    </el-col>
                    <el-col :span="20">
                        <img class="personal-info-avatar" :src="personalInfo.avatarUrl" width="40px" height="40px">
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="姓名" prop="realName" class="personal-info-realName">
                            <el-input v-model="personalInfo.realName" :disabled="true"></el-input>
                            <el-input v-model="personalInfoEditForm.realName" :maxlength="20" @change="updateParams"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <el-checkbox label="mobile">手机号</el-checkbox>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item>
                            <el-input :value="personalInfo.mobile" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <div class="personal-info-text">{{personalInfoEditForm.mobile}}</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <el-checkbox label="wechat">微信号</el-checkbox>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item>
                            <el-input :value="personalInfo.wechat" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item prop="wechat">
                            <el-input v-model="personalInfoEditForm.wechat" @change="updateParams" :maxlength="25"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <el-checkbox label="email">邮箱</el-checkbox>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item>
                            <el-input :value="personalInfo.email" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <div class="personal-info-text">{{personalInfoEditForm.email}}</div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                        <el-checkbox label="signature" :disabled="!isSingleShare">个性签名</el-checkbox>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item prop="signature">
                            <el-input
                                v-model="personalInfoEditForm.signature"
                                type="textarea"
                                rows="2"
                                :maxlength="100"
                                placeholder="请输入个性签名，如专注于全国产品运营互联网方向，政府事务，人工智能架构等" @change="updateParams"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="4">
                        <el-checkbox label="wechatQrcodeUrl" :disabled="checkboxDisabled">微信二维码</el-checkbox>
                    </el-col>
                    <el-col :span="20">
                        <img class="personal-info-wechat-qrcode" :src="personalInfo.wechatQrcodeUrl" width="40px" height="40px">
                    </el-col>
                </el-row> -->
            </el-checkbox-group>
        </el-form>
        <div v-if="isPersonalInfoIncomplete" class="personal-info-edit-incomplete">
            个人资料不完善
            <el-button type="text" @click="jumpPersonalCenter">去补充</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 是否个人资料不完善
        isPersonalInfoIncomplete: {
            type: Boolean,
            default: false
        },
        // 是否是单个职位分享
        isSingleShare: {
            type: Boolean,
            default: true
        },
        // 是否是更新名片信息
        isUpdateInfo: {
            type: Boolean,
            default: false
        },
        personalInfo: {
            type: Object,
            default: {
                avatarUrl: "",
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "",
                wechatQrcodeUrl: ""
            }
        },
        personalEditInfo: {
            type: Object,
            default: {
                avatarUrl: "",
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "",
                wechatQrcodeUrl: ""
            }
        },
        personalInfoShowItems: {
            type: Array
        }
    },
    watch: {
        personalEditInfo: {
            handler: function(val) {
                this.personalInfoEditForm = Object.assign({}, val);
            },
            deep: true,
            immediate: true
        },
        personalInfoShowItems: {
            handler: function(val) {
                if(val && val.length) {
                    this.personalInfoItemShowList = ['avatarUrl', 'signature', 'wechatQrcodeUrl'].concat(val);
                }
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        checkboxDisabled() {
            if(!this.isSingleShare) {
                if(!this.personalInfoShowItems.length) {
                    this.personalInfoItemShowList = ['avatarUrl', 'signature', 'wechatQrcodeUrl'];
                }
                return true;
            }
            if(this.isSingleShare) {
                let checkList = ['mobile', 'wechat', 'email', 'signature'];
                for(let i = 0; i < checkList.length; i++) {
                    if(this.personalInfoItemShowList.indexOf(checkList[i]) > -1) {
                        if(this.personalInfoItemShowList.indexOf('avatarUrl') === -1) {
                            this.personalInfoItemShowList.push('avatarUrl');
                        }
                        if(this.personalInfoItemShowList.indexOf('wechatQrcodeUrl') === -1) {
                            this.personalInfoItemShowList.push('wechatQrcodeUrl');
                        }
                        return true;
                    }
                }
                return false;
            }
        }
    },
    data() {
        return {
            personalInfoEditForm: {
                realName: "",
                mobile: "",
                wechat: "",
                email: "",
                signature: "" 
            },
            personalInfoEditFormRules: {
                realName: [{
                    validator: (rules, value, callback) => {
                        this.validatePersonalInfoItem('avatarUrl', value, callback)
                    }, trigger: 'blur'
                }],
                wechat: [{
                    validator: (rules, value, callback) => {
                        this.validatePersonalInfoItem('wechat', value, callback)
                    }, trigger: 'blur'
                }],
                signature: [{
                    validator: (rules, value, callback) => {
                        this.validatePersonalInfoItem('signature', value, callback)
                    }, trigger: 'blur'
                }]
            },
            personalInfoItemShowList: []
        }
    },
    mounted() {},
    methods: {
        validatePersonalInfoItem(itemName, value, callback) {
            if(this.personalInfoItemShowList.indexOf(itemName) > -1 && !value) {
                let itemTextName = "";
                switch(itemName) {
                    case 'avatarUrl': itemTextName = "姓名"; break;
                    case 'wechat': itemTextName = "微信号"; break;
                    case 'signature': itemTextName = "个性签名"; break;
                }
                callback(`请填写${itemTextName}`)
            } else {
                callback()
            }
        },
        handlePersonalInfoItemShow() {
            this.updateParams();
        },
        updateParams() {
            this.$refs.personalInfoEditForm.validate(valid => {
                this.$emit('userCardInfoFormValid', valid);
                if(valid) {
                    let userCardInfoParams = {};
                    if(!this.isUpdateInfo) {
                        this.personalInfoItemShowList.forEach(item => {
                            if(item === 'avatarUrl' || item === 'wechatQrcodeUrl') {
                                // userCardInfoParams.avatarUrl = this.personalInfoEditForm.avatarUrl;
                                userCardInfoParams.realName = this.personalInfoEditForm.realName;
                            } else {
                                userCardInfoParams[item] = this.personalInfoEditForm[item];
                            }
                        })
                    } else {
                        Object.keys(this.personalInfoEditForm).forEach(key => {
                            if(this.personalInfoItemShowList.indexOf(key) > -1) {
                                if(key === 'avatarUrl' || key === 'wechatQrcodeUrl') {
                                    userCardInfoParams.realName = this.personalInfoEditForm.realName;
                                } else {
                                    userCardInfoParams[key] = this.personalInfoEditForm[key];
                                }
                            } else if(key !== 'realName') {
                                userCardInfoParams[key] = "";
                            }
                        })
                    }
                    this.$emit('updateUserCardInfoParams', {
                        userCardInfoParams: userCardInfoParams,
                        personalInfoShowItems: this.personalInfoItemShowList
                    });
                }
            })
        },
        reset() {
            if(!this.isSingleShare) {
                if(!this.personalInfoShowItems.length) {
                    this.personalInfoItemShowList = ['avatarUrl', 'signature', 'wechatQrcodeUrl'];
                } else {
                    this.personalInfoItemShowList = ['avatarUrl', 'signature', 'wechatQrcodeUrl'].concat(this.personalInfoShowItems);
                }
            } else {
                this.personalInfoItemShowList = [];
            }
        },
        jumpPersonalCenter() {
            this.$emit('close-dialog');
            // window.open('/Headhunting/MyCompany.html#/personalCenter?Tab=BasicInfo');
            window.open('/#/personalCenter?Tab=BasicInfo');
        }
    }
}
</script>

<style lang="scss" scope>
.personal-info-edit-from.el-form {
    margin: 0;
    padding: 0;
    .el-col-4 {
        padding-right: 3px;
    }
    .el-col-20, .el-col-10 {
        padding-left: 15px;
    }
    .el-checkbox {
        .el-checkbox__label {
            color: #333;
        }
        &.is-disabled.is-checked {
            .el-checkbox__input .el-checkbox__inner {
                border-color: #999;
                background-color: #fff;
                &::after {
                    border-color: #999;
                }
            }
        }
    }
    .el-form-item {
        margin-bottom: 16px;
    }
    .el-form-item__label {
        text-align: left;
        padding-left: 24px;
        padding-bottom: 0;
        margin-bottom: 0;
        width: calc(16.66667% + 15px) !important;
    }
    .el-form-item__content, .el-form-item__label {
        line-height: 28px;
    }
    .personal-info-avatar {
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 10px;
    }
    .personal-info-wechat-qrcode {
        width: 100px;
        height: 100px;
        margin-top: 4px;
        margin-bottom: 10px;
    }
    .personal-info-realName.el-form-item {
        .el-form-item__content {
            margin-left: calc(16.66667% + 15px) !important;
        }
        .el-input {
            width: 50%;
            &:nth-of-type(1) {
                padding-right: 7.5px;
            }
            &:nth-of-type(2) {
                padding-left: 7.5px;
            }
        }
        .el-form-item__error {
            left: 192.5px;
        }
    }
    .personal-info-text {
        line-height: 28px;
        font-size: 14px;
        padding: 0 10px;
        margin-bottom: 16px;
    }
    .el-input, .el-textarea {
        .el-input__inner {
            height: 28px;
            line-height: 28px;
        }
        .el-input__inner, .el-textarea__inner {
            padding: 0 10px;
            border-color: #ddd;
            color: #666;
        }
        &.is-disabled {
            .el-input__inner, .el-textarea__inner {
                color: #999;
                background-color: #f8f8f8;
            }
        }
        &.salary-input {
            width: 50%;
            &:nth-of-type(1) {
                padding-right: 5px;
            }
            &:nth-of-type(2) {
                padding-left: 5px;
            }
        }
    }
    .el-textarea .el-textarea__inner {
        padding: 5px 10px;
    }
    .el-textarea + .el-textarea {
        margin-top: 10px;
    }
    .el-textarea.is-disabled .el-textarea__inner {
        resize: none;
    }
}

.personal-info-edit-incomplete {
    text-align: center;
}
</style>