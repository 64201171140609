var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isPersonalInfoIncomplete
        ? _c(
            "el-form",
            {
              ref: "personalInfoEditForm",
              staticClass: "personal-info-edit-from",
              attrs: {
                "label-width": "0",
                model: _vm.personalInfoEditForm,
                rules: _vm.personalInfoEditFormRules,
              },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handlePersonalInfoItemShow },
                  model: {
                    value: _vm.personalInfoItemShowList,
                    callback: function ($$v) {
                      _vm.personalInfoItemShowList = $$v
                    },
                    expression: "personalInfoItemShowList",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                label: "avatarUrl",
                                disabled: _vm.checkboxDisabled,
                              },
                            },
                            [_vm._v("头像")]
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("img", {
                          staticClass: "personal-info-avatar",
                          attrs: {
                            src: _vm.personalInfo.avatarUrl,
                            width: "40px",
                            height: "40px",
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "personal-info-realName",
                              attrs: { label: "姓名", prop: "realName" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.personalInfo.realName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.personalInfo, "realName", $$v)
                                  },
                                  expression: "personalInfo.realName",
                                },
                              }),
                              _c("el-input", {
                                attrs: { maxlength: 20 },
                                on: { change: _vm.updateParams },
                                model: {
                                  value: _vm.personalInfoEditForm.realName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.personalInfoEditForm,
                                      "realName",
                                      $$v
                                    )
                                  },
                                  expression: "personalInfoEditForm.realName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-checkbox", { attrs: { label: "mobile" } }, [
                            _vm._v("手机号"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.personalInfo.mobile,
                                  disabled: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c("div", { staticClass: "personal-info-text" }, [
                          _vm._v(_vm._s(_vm.personalInfoEditForm.mobile)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-checkbox", { attrs: { label: "wechat" } }, [
                            _vm._v("微信号"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.personalInfo.wechat,
                                  disabled: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "wechat" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: 25 },
                                on: { change: _vm.updateParams },
                                model: {
                                  value: _vm.personalInfoEditForm.wechat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.personalInfoEditForm,
                                      "wechat",
                                      $$v
                                    )
                                  },
                                  expression: "personalInfoEditForm.wechat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-checkbox", { attrs: { label: "email" } }, [
                            _vm._v("邮箱"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  value: _vm.personalInfo.email,
                                  disabled: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c("div", { staticClass: "personal-info-text" }, [
                          _vm._v(_vm._s(_vm.personalInfoEditForm.email)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                label: "signature",
                                disabled: !_vm.isSingleShare,
                              },
                            },
                            [_vm._v("个性签名")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "signature" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: "2",
                                  maxlength: 100,
                                  placeholder:
                                    "请输入个性签名，如专注于全国产品运营互联网方向，政府事务，人工智能架构等",
                                },
                                on: { change: _vm.updateParams },
                                model: {
                                  value: _vm.personalInfoEditForm.signature,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.personalInfoEditForm,
                                      "signature",
                                      $$v
                                    )
                                  },
                                  expression: "personalInfoEditForm.signature",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isPersonalInfoIncomplete
        ? _c(
            "div",
            { staticClass: "personal-info-edit-incomplete" },
            [
              _vm._v("\n        个人资料不完善\n        "),
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.jumpPersonalCenter },
                },
                [_vm._v("去补充")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }