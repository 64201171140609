var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "520px",
        title: "我的名片信息",
        "custom-class": "job-personal-info-dialog",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _vm.isFirstEdit
        ? _c("span", { staticClass: "first-edit-tip" }, [
            _vm._v(
              "\n        第一次制作职位名片，请先编辑你的名片信息。\n    "
            ),
          ])
        : _vm._e(),
      _c("job-personal-info-edit", {
        ref: "jobPersonalInfoEdit",
        attrs: {
          isPersonalInfoIncomplete: _vm.isPersonalInfoIncomplete,
          isSingleShare: false,
          isUpdateInfo: true,
          personalInfo: _vm.personalInfo,
          personalEditInfo: _vm.personalEditInfo,
          personalInfoShowItems: _vm.personalInfoShowItems,
        },
        on: {
          "close-dialog": _vm.handleCloseDialog,
          updateUserCardInfoParams: _vm.updateUserCardInfoParams,
          userCardInfoFormValid: (valid) => {
            _vm.userCardInfoFormValid = valid
          },
        },
      }),
      !_vm.isPersonalInfoIncomplete
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.personalInfoEditComfirm },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }