var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "520px",
        "custom-class": "job-share-edit-dialog",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "job-share-edit-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _vm.isSingleShare || _vm.isFromShareTable
            ? [
                _vm._v("\n            编辑职位信息\n            "),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      "popper-class": "single-edit-tip",
                      effect: "light",
                      content:
                        "灰色文本框为猎必得职位详情对应的内容，白色文本框为分享页面对应的内容，可编辑。",
                      placement: "right-start",
                      "visible-arrow": false,
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon-question" },
                      [
                        _c("font-icon", {
                          staticClass: "question-hover",
                          attrs: { href: "#icon-talent_ql" },
                        }),
                        _c("font-icon", {
                          staticClass: "question-no-hover",
                          attrs: { href: "#icon-talent_qd" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm.jobSharingId
            ? [_vm._v("\n            编辑对外信息\n        ")]
            : [_vm._v("\n            添加到我的名片\n        ")],
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.jobInfoLoading,
              expression: "jobInfoLoading",
            },
          ],
          staticClass: "job-share-edit-form-container",
        },
        [
          _vm.isSingleEdit
            ? [
                _c(
                  "el-form",
                  {
                    ref: "jobShareEditForm",
                    staticClass: "job-share-edit-form",
                    attrs: {
                      "label-width": "0",
                      model: _vm.jobShareEditForm,
                      rules: _vm.jobShareEditFormRules,
                    },
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.handleJobShareItemShow },
                        model: {
                          value: _vm.jobShareItemShowList,
                          callback: function ($$v) {
                            _vm.jobShareItemShowList = $$v
                          },
                          expression: "jobShareItemShowList",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "jobName", disabled: "" } },
                                  [_vm._v("职位名称")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.jobName,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "jobName" } },
                                  [
                                    _c("el-input", {
                                      attrs: { maxlength: 128 },
                                      model: {
                                        value: _vm.jobShareEditForm.jobName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.jobShareEditForm,
                                            "jobName",
                                            $$v
                                          )
                                        },
                                        expression: "jobShareEditForm.jobName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "customerName" } },
                                  [_vm._v("公司名称")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.customerName,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "customerName" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请不要直接写客户名称",
                                        maxlength: 64,
                                      },
                                      model: {
                                        value:
                                          _vm.jobShareEditForm.customerName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.jobShareEditForm,
                                            "customerName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "jobShareEditForm.customerName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: "location", disabled: "" },
                                  },
                                  [_vm._v("工作地点")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.location,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 10 } }, [
                              _c("div", { staticClass: "job-info-text" }, [
                                _vm._v(_vm._s(_vm.jobShareEditForm.location)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "yearOfExperience" } },
                                  [_vm._v("工作经验")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.yearOfExperience,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 10 } }, [
                              _c("div", { staticClass: "job-info-text" }, [
                                _vm._v(
                                  _vm._s(_vm.jobShareEditForm.yearOfExperience)
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "degree" } },
                                  [_vm._v("学历要求")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.degree,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 10 } }, [
                              _c("div", { staticClass: "job-info-text" }, [
                                _vm._v(_vm._s(_vm.jobShareEditForm.degree)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { label: "salary" } },
                                  [_vm._v("薪资范围")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.salary,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "salary" } },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "salary-input",
                                        model: {
                                          value: _vm.jobShareEditForm.minSalary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.jobShareEditForm,
                                              "minSalary",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "jobShareEditForm.minSalary",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "suffix" }, [
                                          _vm._v("K(千)  "),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "salary-input",
                                        model: {
                                          value: _vm.jobShareEditForm.maxSalary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.jobShareEditForm,
                                              "maxSalary",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "jobShareEditForm.maxSalary",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "suffix" }, [
                                          _vm._v("K(千)  "),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      label: "description",
                                      disabled: "",
                                    },
                                  },
                                  [_vm._v("工作描述")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 20 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { prop: "description" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: _vm.jobInfo.description,
                                        disabled: true,
                                        type: "textarea",
                                        autosize: { minRows: 2, maxRows: 4 },
                                      },
                                    }),
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        autosize: { minRows: 2, maxRows: 4 },
                                        maxlength: 8000,
                                      },
                                      model: {
                                        value: _vm.jobShareEditForm.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.jobShareEditForm,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "jobShareEditForm.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "batch-edit-tip" },
                  [
                    _vm._v(
                      "\n                已勾选" +
                        _vm._s(_vm.jobIdList.length) +
                        "个职位，"
                    ),
                    _vm.sharedCount
                      ? [
                          _vm._v(
                            "其中" +
                              _vm._s(_vm.sharedCount) +
                              "个职位已在名片中，"
                          ),
                        ]
                      : _vm._e(),
                    _vm._v("请选择编辑要分享出去的职位项\n            "),
                  ],
                  2
                ),
                _c(
                  "el-checkbox-group",
                  {
                    staticClass: "batch-edit-checkbox",
                    on: { change: _vm.handleJobShareItemShow },
                    model: {
                      value: _vm.jobShareItemShowList,
                      callback: function ($$v) {
                        _vm.jobShareItemShowList = $$v
                      },
                      expression: "jobShareItemShowList",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "jobName", disabled: "" } },
                              [_vm._v("职位名称")]
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isBatchEditJobName,
                                expression: "isBatchEditJobName",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-down",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditJobName = false
                              },
                            },
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isBatchEditJobName,
                                expression: "!isBatchEditJobName",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-right",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditJobName = true
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isBatchEditJobName,
                            expression: "isBatchEditJobName",
                          },
                        ],
                        staticClass: "batch-edit-container",
                        attrs: { id: "batch_jobName" },
                      },
                      [
                        _vm._l(_vm.addShareJobs, function (job, index) {
                          return [
                            _c(
                              "el-row",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: job.defaultJobInfo.jobName,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-input", {
                                      staticClass: "batch-input-item",
                                      attrs: { maxlength: 128 },
                                      on: {
                                        blur: (ev) => {
                                          _vm.handleBatchEditJobInfo(
                                            ev.target,
                                            "jobName",
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: job.sharingJobInfo.jobName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            job.sharingJobInfo,
                                            "jobName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "job.sharingJobInfo.jobName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "customerName" } },
                              [_vm._v("公司名称")]
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isBatchEditCustomerName,
                                expression: "isBatchEditCustomerName",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-down",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditCustomerName = false
                              },
                            },
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isBatchEditCustomerName,
                                expression: "!isBatchEditCustomerName",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-right",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditCustomerName = true
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isBatchEditCustomerName,
                            expression: "isBatchEditCustomerName",
                          },
                        ],
                        staticClass: "batch-edit-container",
                        attrs: { id: "batch_customerName" },
                      },
                      [
                        _vm._l(_vm.addShareJobs, function (job, index) {
                          return [
                            _c(
                              "el-row",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: job.defaultJobInfo.customerName,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-input", {
                                      staticClass: "batch-input-item",
                                      attrs: {
                                        maxlength: 64,
                                        placeholder: "请不要直接写客户名称",
                                      },
                                      on: {
                                        blur: (ev) => {
                                          _vm.handleBatchEditJobInfo(
                                            ev.target,
                                            "customerName",
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: job.sharingJobInfo.customerName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            job.sharingJobInfo,
                                            "customerName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "job.sharingJobInfo.customerName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "location", disabled: "" } },
                              [_vm._v("工作地点")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "yearOfExperience" } },
                              [_vm._v("工作经验")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-checkbox", { attrs: { label: "degree" } }, [
                              _vm._v("学历要求"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c("el-checkbox", { attrs: { label: "salary" } }, [
                              _vm._v("薪资范围"),
                            ]),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isBatchEditSalary,
                                expression: "isBatchEditSalary",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-down",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditSalary = false
                              },
                            },
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isBatchEditSalary,
                                expression: "!isBatchEditSalary",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-right",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditSalary = true
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isBatchEditSalary,
                            expression: "isBatchEditSalary",
                          },
                        ],
                        staticClass: "batch-edit-container",
                        attrs: { id: "batch_salary" },
                      },
                      [
                        _vm._l(_vm.addShareJobs, function (job, index) {
                          return [
                            _c(
                              "el-row",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: job.defaultJobInfo.salary,
                                        disabled: true,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticClass:
                                          "salary-input batch-input-item",
                                        on: {
                                          blur: (ev) => {
                                            _vm.handleBatchEditJobInfo(
                                              ev.target,
                                              "salary",
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: job.sharingJobInfo.minSalary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              job.sharingJobInfo,
                                              "minSalary",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "job.sharingJobInfo.minSalary",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "suffix" }, [
                                          _vm._v("K(千)  "),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "salary-input",
                                        on: {
                                          blur: (ev) => {
                                            _vm.handleBatchEditJobInfo(
                                              ev.target,
                                              "salary",
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: job.sharingJobInfo.maxSalary,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              job.sharingJobInfo,
                                              "maxSalary",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "job.sharingJobInfo.maxSalary",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "suffix" }, [
                                          _vm._v("K(千)"),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "el-row",
                      { staticClass: "batch-edit-row" },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "description", disabled: "" } },
                              [_vm._v("工作描述")]
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 4 } }, [
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isBatchEditDescription,
                                expression: "isBatchEditDescription",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-down",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditDescription = false
                              },
                            },
                          }),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isBatchEditDescription,
                                expression: "!isBatchEditDescription",
                              },
                            ],
                            staticClass: "batch-edit-icon el-icon-arrow-right",
                            on: {
                              click: function ($event) {
                                _vm.isBatchEditDescription = true
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isBatchEditDescription,
                            expression: "isBatchEditDescription",
                          },
                        ],
                        staticClass: "batch-edit-container",
                        attrs: { id: "batch_description" },
                      },
                      [
                        _vm._l(_vm.addShareJobs, function (job, index) {
                          return [
                            _c(
                              "el-row",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        value: job.defaultJobInfo.description,
                                        disabled: true,
                                        type: "textarea",
                                        autosize: { minRows: 2, maxRows: 4 },
                                      },
                                    }),
                                    _c("el-input", {
                                      staticClass: "batch-input-item",
                                      attrs: {
                                        type: "textarea",
                                        autosize: { minRows: 2, maxRows: 4 },
                                        maxlength: 8000,
                                      },
                                      on: {
                                        blur: (ev) => {
                                          _vm.handleBatchEditJobInfo(
                                            ev.target,
                                            "description",
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: job.sharingJobInfo.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            job.sharingJobInfo,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "job.sharingJobInfo.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "personal-info-edit" }, [
        _c(
          "div",
          { staticClass: "personal-info-edit-title" },
          [
            _vm.isSingleShare
              ? [
                  _vm._v(
                    "\n                编辑我的信息（可不选）\n            "
                  ),
                ]
              : _vm._e(),
            !_vm.isSingleShare && !_vm.isEditPersonalInfo
              ? [
                  _vm._v("\n                我的名片信息\n                "),
                  !_vm.isPersonalInfoIncomplete
                    ? _c(
                        "span",
                        {
                          staticClass: "text-operate-btn",
                          on: {
                            click: function ($event) {
                              _vm.isEditPersonalInfo = true
                            },
                          },
                        },
                        [_vm._v("编辑")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            !_vm.isSingleShare && _vm.isEditPersonalInfo
              ? [_vm._v("\n                编辑我的信息\n            ")]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPersonalInfoIncomplete,
                expression: "isPersonalInfoIncomplete",
              },
            ],
            staticClass: "personal-info-edit-body-incomplete",
          },
          [
            _vm._v("\n            个人资料不完善\n            "),
            _c(
              "el-button",
              {
                attrs: { type: "text" },
                on: { click: _vm.jumpPersonalCenter },
              },
              [_vm._v("去补充")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isPersonalInfoIncomplete,
                expression: "!isPersonalInfoIncomplete",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.personalInfoLoading,
                expression: "personalInfoLoading",
              },
            ],
            staticClass: "personal-info-edit-body",
          },
          [
            _c("job-personal-info-edit", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.isSingleShare ||
                    (!_vm.isSingleShare && _vm.isEditPersonalInfo),
                  expression:
                    "isSingleShare || (!isSingleShare && isEditPersonalInfo)",
                },
              ],
              ref: "jobPersonalInfoEdit",
              attrs: {
                isSingleShare: _vm.isSingleShare,
                isUpdateInfo: !_vm.isSingleShare && _vm.isEditPersonalInfo,
                personalInfo: _vm.personalInfo,
                personalEditInfo: _vm.personalEditInfo,
                personalInfoShowItems: _vm.isSingleShare
                  ? []
                  : _vm.personalInfoShowItems,
              },
              on: {
                updateUserCardInfoParams: _vm.updateUserCardInfoParams,
                userCardInfoFormValid: (valid) => {
                  _vm.userCardInfoFormValid = valid
                },
              },
            }),
            !_vm.isSingleShare
              ? _c("job-personal-info-card", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditPersonalInfo,
                      expression: "!isEditPersonalInfo",
                    },
                  ],
                  ref: "jobPersonalInfoCard",
                  attrs: {
                    personalInfo: _vm.personalEditInfo,
                    personalInfoShowItems: _vm.personalInfoShowItems,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      !_vm.isPersonalInfoIncomplete
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAndShare },
                },
                [_vm._v(_vm._s(_vm.isFromShareTable ? "保存" : "保存并分享"))]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }